@import "../../styles/tools";

.toasts-list {
    position: fixed;
    top: 64px;
    left: calc(100vw - var(--common-scroll-width));
    transform: translate(-100%, 0);
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 0 10px;
    z-index: 2050;
}

@mixin toastColorStyles($backgroundColor, $textColor) {
    background: $backgroundColor;
    color: $textColor;

    &:hover {
        background: darken($backgroundColor, 10%);
    }
}

.toast {
    display: block;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    margin-bottom: 10px;
    width: 320px;
    max-width: 100%;
    text-align: left;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
    font-size: 16px;
    line-height: 22px;

    transition: all 0.175s ease-in-out, 
        transform 0.5s ease-in-out,
        opacity 0.5s ease-in-out;

    &.toast--created {
        transform: translateY(150px);
        opacity: 0;
    }

    &.toast--idle {
        opacity: 1;
    }

    &.toast--closed {
        transform: translateX(150%);
    }

    &.toast--info {
        @include toastColorStyles(#F0F5FA, #000);
    }

    &.toast--success {
        @include toastColorStyles($color-green, #fff);
    }

    &.toast--warning {
        @include toastColorStyles($color-orange, #fff);
    }

    &.toast--danger {
        @include toastColorStyles($color-red--text, #fff);
    }
}

@include media-mobile {
    .toasts-list {
        width: 100%;
        right: 0;
        left: 0;
        transform: none;
    }

    .toast {
        width: 100%;
    }
}