@import '../../styles/tools';

.content__container {
    background-color: #DFE5E3;
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
    border-radius: 6px;
    min-width: 300px;
    max-width: 400px;
}

.content__container--size-small {
    min-width: inherit;
}

.popup__container {
    & > * {
        padding: 0 !important;
    }
}

.section {
    background: $color-off-white;
    width: 100%;
    text-align: left;
    overflow: hidden;
    padding: 6px 16px 4px 8px;
    color: $color-title-active;
    font-weight: 500;
    font-size: 14px;
}

.button {
    background: $color-off-white;
    border: none;
    appearance: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    padding: 16px;
    transition: all 0.2s ease-in-out;
    color: $color-title-active !important;
    text-decoration: none !important;

    &:hover {
        background: $color-background;
    }

    &:active {
        background: darken($color-background, 3%);
    }
}